import { createTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'

//components
import MuiFilledInput from './MuiFilledInput'
import MuiTypography from './MuiTypography'
import MuiButton from './MuiButton'
import MuiFormHelperText from './MuiFormHelperText'
import MuiFormControl from './MuiFormControl'
import MuiPopover from './MuiPopover'
import MuiTextField from './MuiTextField'
import MuiInputLabel from './MuiInputLabel'
import MuiCssBaseline from './MuiCssBaseline'
import MuiAutocomplete from './MuiAutocomplete'
import MuiInputBase from './MuiInputBase'
import MuiInput from './MuiInput'
import MuiSelect from './MuiSelect'

export default createTheme({
    palette: {
        primary: {
            main: '#006554',
        },
        secondary: {
            main: '#CD4913',
            dark: '#F34116',
        },
        grey: {
            '100': '#F7F8F6',
        },
        text: {
            primary: '#2E2F41',
            secondary: '#8F98A6',
        },
    },
    utils: {
        shadows: {
            main: `0px 0px 10px 0px ${alpha('#000', 0.1)}`,
        },
        headerHeight: 70,
    },
    shape: {
        borderRadius: 5,
    },
    spacing: 5,
    components: {
        MuiFilledInput,
        MuiTypography,
        MuiButton,
        MuiFormHelperText,
        MuiFormControl,
        MuiPopover,
        MuiTextField,
        MuiInputLabel,
        MuiCssBaseline,
        MuiAutocomplete,
        MuiInputBase,
        MuiInput,
        MuiSelect,
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Barlow', 'sans-serif'].join(','),
    },
})
