import { styled } from '@mui/material'

export const StyledTable = styled('table')`
    margin: ${({ theme }) => `${theme.spacing(5)} 0 ${theme.spacing(5)} 0`};
`

export const StyledTableTd = styled('td')`
    font-size: 1.4rem;
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)}`};
    &:nth-of-type(2),
    &:nth-of-type(3) {
        text-align: center;
    }
`
export const StyledTableTh = styled('th')`
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)}`};
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.palette.grey[500]};
`
