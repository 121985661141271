import { red } from '@mui/material/colors'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiFormHelperText: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: {
            color: red[800],
            margin: 0,
            fontSize: '1.1rem',
            paddingLeft: '10px',
        },
    },
    variants: [],
}

export default MuiFormHelperText
