import { styled } from '@mui/system'
import { NavLink } from 'react-router-dom'

export const StyledNavLink = styled(NavLink)`
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(4)}`};
    position: relative;

    svg {
        font-size: 2.5rem;
    }

    &:after {
        content: '';
        position: absolute;
        left: calc(100% - 1px);
        top: 0;
        height: 100%;
        width: 2px;
        background: ${({ theme }) => theme.palette.primary.main};
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.active {
        color: ${({ theme }) => theme.palette.primary.main};
        position: relative;

        &:after {
            opacity: 1;
        }
    }
`
