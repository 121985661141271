import { MenuItem } from '@mui/material'
import { styled } from '@mui/system'

export const StyledSidebar = styled('aside')`
    display: flex;
    flex-direction: column;
    width: 70px;
    position: relative;
    border-right: 1px solid ${({ theme }) => theme.palette.grey['200']};
    .MuiList-root {
        padding: 0;
    }
`

export const StyledNavigation = styled('nav')`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const StyledMenuItem = styled(MenuItem)`
    padding: 0;
`

export const StyledLogo = styled('img')`
    margin: ${({ theme }) => `${theme.spacing(0)} ${theme.spacing(2)} 7px ${theme.spacing(2)}`};
    max-width: 50px;
`
