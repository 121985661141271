import React from 'react'
import { Button, Divider } from '@mui/material'
import Input from '../../components/Input'
import Form from '../../components/Form'
import { FORM_SIGN_IN } from '../../utils/constans'
import { Box, Theme } from '@mui/system'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { InputFields } from 'forms'

type Props = {
    onSubmit: SubmitHandler<SignInForm>
    loading: boolean
}
interface SignInForm {
    email: string
    password: string
}

const FormSignIn = ({ onSubmit, loading }: Props) => {
    const methods = useForm<SignInForm>()
    const { handleSubmit } = methods
    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <>
                    {FORM_SIGN_IN.map(
                        (
                            { name, type, label, placeholder, pattern, isRequired }: InputFields,
                            i
                        ) => (
                            <Input
                                key={i}
                                name={name}
                                type={type}
                                pattern={pattern}
                                label={label}
                                placeholder={placeholder}
                                isRequired={isRequired}
                                {...(type === 'password' && { passwordInput: true })}
                            />
                        )
                    )}
                    <Divider
                        sx={{
                            margin: (theme: Theme) => `${theme.spacing(5)} 0`,
                        }}
                    />
                    <Box display="flex" justifyContent={'flex-end'}>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            sx={{ minWidth: '140px' }}
                        >
                            Zaloguj się
                        </Button>
                    </Box>
                </>
            </Form>
        </FormProvider>
    )
}

export default FormSignIn
