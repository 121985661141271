import { Contractor } from 'contractors'
import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    WithFieldValue,
} from 'firebase/firestore'
import { User } from '../types/users'
import { ObjectData } from '../types/objects'

export const objectConverter: FirestoreDataConverter<ObjectData> = {
    toFirestore(object: WithFieldValue<ObjectData>): DocumentData {
        return {
            name: object.name,
            localisation: object.localisation,
            objectID: object.objectID,
        }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ObjectData {
        const data = snapshot.data(options)
        return {
            id: snapshot.id,
            name: data.name,
            localisation: data.localisation,
            objectID: data.objectID,
        }
    },
}

export const contractorConverter: FirestoreDataConverter<Contractor> = {
    toFirestore(contractor: WithFieldValue<Contractor>): DocumentData {
        return {
            name: contractor.name,
            idSpeed: contractor.contractorID,
        }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Contractor {
        const data = snapshot.data(options)
        return {
            id: snapshot.id,
            contractorID: data.contractorID,
            name: data.name,
        }
    },
}
export const userConverter: FirestoreDataConverter<User> = {
    toFirestore(user: WithFieldValue<User>): DocumentData {
        return {
            email: user.email,
            name: user.name,
            objects: user.objects,
            lastName: user.lastName,
            contractors: user.contractors,
        }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
        const data = snapshot.data(options)
        return {
            id: snapshot.id,
            email: data.email,
            name: data.name,
            description: data.description,
            lastName: data.lastName,
            contractors: data.contractors,
            objects: data.objects.filter((o: string) => Boolean(o)),
            isActive: data.isActive,
            lang: data.lang,
        }
    },
}
