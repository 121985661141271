import { InputBase, ListItemText, MenuItem, TextField, Transitions } from '@mui/material'
import { styled, alpha } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

export const StyledInput = styled(InputBase)`
    box-shadow: ${({ theme }) => theme.utils.shadows.main};
    margin: 0;

    && .MuiAutocomplete-inputRoot,
    &&& .MuiFilledInput-root {
        padding: 0;
    }
    && .MuiInputBase-input {
        padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)}`};
        &:hover {
            background: transparent;
        }
    }
    & .MuiButtonBase-root {
        margin-right: ${({ theme }) => `${theme.spacing(1)}`};
    }
    &,
    .MuiFilledInput-root {
        border: 1px solid transparent;
        position: relative;
        background-color: ${({ theme }) => theme.palette.common.white + '!important'};
        width: 100%;
        border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
        transition: ${({ theme }) =>
            (theme.transitions as Transitions).create([
                'border-color',
                'background-color',
                'box-shadow',
            ])};
        font-size: 1.4rem;
    }
    .MuiInputBase-root,
    .MuiFilledInput-root {
        &:hover {
            background: transparent;
        }
    }
    .MuiAutocomplete-option[data-focus='true'] {
        background: red;
    }
`

export const StyledSelectedItem = styled('div')`
    background-color: ${({ theme }) => theme.palette.text.primary};
    color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
    margin: ${({ theme }) => `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`};
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
    display: flex;
    align-items: center;
    font-size: 1.4rem;
`

export const StyledMultiInput = styled(TextField)`
    .MuiInput-root {
        padding: ${({ theme }) => `${theme.spacing(1)}`};
        font-size: 1.4rem;
        &:before,
        &:after {
            display: none;
        }
    }

    .MuiAutocomplete-popupIndicator {
        display: none;
    }
`

export const StyledCloseIcon = styled(CloseIcon)`
    color: ${({ theme }) => theme.palette.common.white};
    margin: ${({ theme }) => `0 0 0 ${theme.spacing(1)}`};
    font-size: 2.5rem;
    cursor: pointer;
`

export const StyledMenuItem = styled(MenuItem)`
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 1.4rem;
`
export const StyledListItem = styled(ListItemText)`
    color: ${({ theme }) => theme.palette.text.primary};
`
export const StyledOption = styled('li')`
    font-size: 1.4rem;
    border-bottom: ${({ theme }) => `1px solid ${alpha(theme.palette.text.primary, 0.05)}`};
    &:hover {
        background: ${({ theme }) => theme.palette.text.primary};
    }
    &[aria-selected='true'] {
        background: ${({ theme }) => alpha(theme.palette.text.primary, 0.08)}!important;
    }
    .MuiCheckbox-root {
        font-size: 3rem;
        svg {
            height: 30px;
        }
    }
`
