import React from 'react'
import { StyledPage, StyledPageContent } from './styles'

type Props = {
    children: JSX.Element
}

const Page = ({ children }: Props) => {
    return (
        <StyledPage px={6} py={5}>
            <StyledPageContent>{children}</StyledPageContent>
        </StyledPage>
    )
}

export default Page
