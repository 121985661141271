import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiCssBaseline: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        html: {
            fontSize: '65%',
        },
        body: {
            overflow: 'hidden',
        },
    },
    variants: [],
}

export default MuiCssBaseline
