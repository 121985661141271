import * as React from 'react'
import Button from '@mui/material/Button'
import { default as MuiDialog } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { t } from 'i18next'

type Props = {
    open: boolean
    title: string
    submitText?: string
    cancelText?: string
    onClose: () => void
    onSubmit: () => void
    children?: JSX.Element
    color?: string
    onSubmitDisable?: boolean
}

const Dialog = ({
    color,
    open,
    onClose,
    children,
    title,
    submitText = t('OK'),
    cancelText = t('Anuluj'),
    onSubmit,
    onSubmitDisable,
}: Props) => {
    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    maxWidth: 350,
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{cancelText}</Button>
                <Button onClick={onSubmit} autoFocus sx={{ color }} disabled={onSubmitDisable}>
                    {submitText}
                </Button>
            </DialogActions>
        </MuiDialog>
    )
}
export default Dialog
