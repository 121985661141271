import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../../../components/Page'
import Heading from '../../../modules/Heading'
import Table from '../../../modules/Table'
import FormContractors from '../../../modules/FormContractors'
import { FirebaseContext } from '../../../firebase'
import Modal from '../../../components/Modal'
import { COLLECTIONS, CRUD, MSG } from '../../../utils/constans'
import { useCrudManager } from '../../../hooks/useCrudManager'
import { useSnackbar } from '../../../hooks/useSnackbar'
import {
    addDoc,
    arrayRemove,
    deleteDoc,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import { collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { contractorConverter } from '../../../firebase/converters'
import { parseMessage } from '../../../utils/helpers'
import Progress from '../../../components/Progress'
import { Column } from 'react-table'
import Dialog from '../../../components/Dialog'
import theme from '../../../theme'
import { Theme } from '@mui/system'
import { Contractor, ContractorForm, ContractorSelected } from 'contractors'
import { Error } from '../../../types'

const Contractors = () => {
    const { t } = useTranslation()
    const { db } = useContext(FirebaseContext)
    const [state, setCreate, setDelete, setUpdate, setRead, loading] = useCrudManager()
    const [alertError, success] = useSnackbar()

    const contractorsQuery = collection(db, COLLECTIONS.CONTRACTORS).withConverter(
        contractorConverter
    )
    const [contractors, contractorsLoading, contractorsError] = useCollectionData(contractorsQuery)

    const onSubmit = (values: ContractorForm) => {
        if (state.operation === CRUD.CREATE) createContractor(values)
        if (state.operation === CRUD.UPDATE) updateContractor(values)
    }

    const handleCreate = () => setCreate()
    const handleUpdate = (item: ContractorForm) => setUpdate(item)
    const handleDelete = (item: ContractorForm) => setDelete(item)
    const handleModalClose = () => setRead()

    const deleteContractor = async () => {
        loading(true)
        const contractorID = (state.item as ContractorSelected).id
        try {
            await deleteDoc(doc(db, COLLECTIONS.CONTRACTORS, contractorID))
            const usersRef = collection(db, COLLECTIONS.USERS)
            const usersQuery = query(
                usersRef,
                where(COLLECTIONS.CONTRACTORS, 'array-contains', contractorID)
            )
            const querySnapshot = await getDocs(usersQuery)
            querySnapshot.forEach(async (user) => {
                const userRef = doc(db, COLLECTIONS.USERS, user.id)
                await updateDoc(userRef, {
                    contractors: arrayRemove(contractorID),
                })
            })
            handleModalClose()
            success(MSG.CONTRACTOR_DELETED)
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const createContractor = async (values: ContractorForm) => {
        loading(true)
        const { name, contractorID } = values
        try {
            await addDoc(collection(db, COLLECTIONS.CONTRACTORS), {
                name,
                contractorID,
            })
            success(MSG.CONTRACTOR_CREATED)
            handleModalClose()
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const updateContractor = async (values: ContractorForm) => {
        loading(true)
        try {
            const ref = doc(db, COLLECTIONS.CONTRACTORS, (state.item as ContractorSelected).id)
            await updateDoc(ref, {
                name: values.name,
                contractorID: values.contractorID,
            })
            success(MSG.CONTRACTOR_UPDATED)
            handleModalClose()
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const contractorData: Array<Contractor> = useMemo(
        () =>
            contractors?.map((c) => {
                return {
                    id: c.id || '',
                    name: c.name,
                    contractorID: c.contractorID,
                }
            }) || [],
        [contractors]
    )

    const columns: Array<Column> = useMemo(
        () => [
            {
                Header: `${t('Firma')}`,
                accessor: 'name',
                size: 9,
            },
            {
                Header: `${t('Identyfikator')}`,
                accessor: 'contractorID',
                size: 3,
            },
        ],
        [t]
    )

    useEffect(() => {
        if (contractorsError) alertError(MSG.ERROR)
    }, [contractorsError, alertError])

    return (
        <Page>
            <>
                <Heading
                    title={t('Kontrahenci')}
                    buttonText={t('Dodaj kontrahenta')}
                    itemsCountText={`Ilość Kontrahentów: ${contractors?.length || ''}`}
                    buttonOnClick={handleCreate}
                />
                {contractorsLoading ? (
                    <Progress />
                ) : (
                    <Table
                        columns={columns}
                        data={contractorData}
                        pageSize={9}
                        onEdit={handleUpdate}
                        onDelete={handleDelete}
                    />
                )}
                <Modal
                    title={
                        state.operation === CRUD.CREATE ? 'Nowy kontrahent' : 'Edytuj kontrahenta'
                    }
                    onClose={handleModalClose}
                    isOpen={state.operation === CRUD.CREATE || state.operation === CRUD.UPDATE}
                >
                    <FormContractors
                        onSubmit={onSubmit}
                        onCancel={handleModalClose}
                        initialValues={state.item}
                        onSubmitText={
                            state.operation === CRUD.CREATE
                                ? 'Dodaj kontrahenta'
                                : 'Zapisz kontrahenta'
                        }
                        loading={state.loading}
                    />
                </Modal>
                <Dialog
                    open={state.operation === CRUD.DELETE}
                    onClose={handleModalClose}
                    title={t('Czy na pewno chcesz usunąć kontrahenta?')}
                    submitText={t('Usuń')}
                    color={(theme as Theme).palette.error.main}
                    onSubmit={deleteContractor}
                    onSubmitDisable={state.loading}
                >
                    <span>
                        {t('Trwale zostaną usunięte dane oraz powiązania konta kontrahenta ')}
                        {(state.item as ContractorSelected)?.name}
                    </span>
                </Dialog>
            </>
        </Page>
    )
}

export default Contractors
