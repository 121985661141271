import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiAutocomplete: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: {
            '&:hover': {
                background: 'transparent',
            },
        },
        option: {
            fontSize: '1.4rem',
        },
    },
    variants: [],
}

export default MuiAutocomplete
