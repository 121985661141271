import React from 'react'
import { Button, Typography, Divider } from '@mui/material'
import { StyledHeading } from './styles'
import AddIcon from '@mui/icons-material/Add'
import { Box, alpha, Theme } from '@mui/system'
import { grey } from '@mui/material/colors'

type Props = {
    title: string
    buttonText?: string
    itemsCountText: string
    buttonOnClick?: () => void
}

const Heading = ({
    title = 'Title',
    buttonText = 'Button',
    buttonOnClick = () => {},
    itemsCountText,
}: Props) => {
    return (
        <StyledHeading>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems={'flex-end'}>
                    <Typography variant="h1" sx={{ mr: 5 }}>
                        {title}
                    </Typography>
                    <Typography variant="h3" color={grey[500]} sx={{ margin: '0 0 5px 0' }}>
                        {itemsCountText}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    onClick={buttonOnClick}
                    startIcon={<AddIcon />}
                    type="submit"
                >
                    {buttonText}
                </Button>
            </Box>
            <Divider
                sx={{
                    mt: 5,
                    mb: 3,
                    borderColor: (theme: Theme) => alpha(theme.palette.text.secondary, 0.1),
                }}
            />
        </StyledHeading>
    )
}

export default Heading
