import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { PATHS } from './routes'

type Props = {
    children: JSX.Element
}

const RequireAuth = ({ children }: Props) => {
    let location = useLocation()
    const { auth } = useAuth()

    if (!auth.user) {
        return <Navigate to={PATHS.SIGNIN} state={{ from: location }} replace />
    }

    return children
}

export default RequireAuth
