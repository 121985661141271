import React, { useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { StyledInput } from '../Input/styles'
import { Option } from '../../types'

type Props = {
    options?: Array<Option>
    disabledOptions?: Array<string | undefined>
    onChange: (data: Option | null) => void
    value: Option | null
    name: string
    placeholder: string
    onBlur: () => void
}

const defaultOption = {
    name: undefined,
    value: undefined,
}

const Select = React.forwardRef(
    ({ options, disabledOptions = [], value, name, onChange, onBlur, placeholder }: Props, ref) => {
        const optionsWithDefault = useMemo(() => [defaultOption, ...(options || [])], [options])

        const handleChange = (e: React.SyntheticEvent, data: Option | null) => {
            onChange && onChange(data)
        }

        return (
            <Autocomplete
                disablePortal
                id={`autocomplete-${name}`}
                disableClearable={false}
                noOptionsText={'Brak'}
                options={optionsWithDefault}
                getOptionLabel={(option) => option.name || ''}
                getOptionDisabled={(option) => disabledOptions?.includes(option.value)}
                renderInput={({ InputProps, ...params }) => (
                    <StyledInput
                        placeholder={placeholder}
                        as={TextField}
                        //@ts-ignore // bug - read prop types from InputBase (ignored 'as' prop)
                        variant="filled"
                        InputProps={{ ...InputProps, disableUnderline: true, endAdornment: false }}
                        {...params}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={handleChange}
                filterOptions={(options, { inputValue }) =>
                    options.filter(
                        (o) =>
                            !!o?.value && o?.name?.toLowerCase()?.includes(inputValue.toLowerCase())
                    )
                }
                value={value || defaultOption}
                onBlur={onBlur}
                ref={ref}
            />
        )
    }
)

export default Select
