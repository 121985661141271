import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../../../components/Page'
import Heading from '../../../modules/Heading'
import Table from '../../../modules/Table'
import FormUser from '../../../modules/FormUser'
import { FirebaseContext } from '../../../firebase'
import Modal from '../../../components/Modal'
import { COLLECTIONS, CRUD, LANGUAGES, MSG } from '../../../utils/constans'
import { useCrudManager } from '../../../hooks/useCrudManager'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { objectConverter, contractorConverter, userConverter } from '../../../firebase/converters'
import { parseMessage, parseOptions } from '../../../utils/helpers'
import Progress from '../../../components/Progress'
import { Switch } from '@mui/material'
import { UserForm, UserSelected } from '../../../types/users'
import { Column, TableInstance } from 'react-table'
import Dialog from '../../../components/Dialog'
import theme from '../../../theme'
import { Theme } from '@mui/system'
import { httpsCallable } from 'firebase/functions'
import { Error } from '../../../types'

const Users = () => {
    const { t } = useTranslation()
    const { db, functions } = useContext(FirebaseContext)
    const [state, setCreate, setDelete, setUpdate, setRead, loading] = useCrudManager()
    const [alertError, success] = useSnackbar()

    const contractorsQuery = collection(db, COLLECTIONS.CONTRACTORS).withConverter(
        contractorConverter
    )
    const [contractors, contractorsLoading, contractorsError] = useCollectionData(contractorsQuery)

    const queryObjects = collection(db, COLLECTIONS.OBJECTS).withConverter(objectConverter)
    const [objects, objectsLoading, objectsError] = useCollectionData(queryObjects)

    const queryUsers = collection(db, COLLECTIONS.USERS).withConverter(userConverter)
    const [users, usersLoading, usersError] = useCollectionData(queryUsers)

    const onSubmit = (values: UserForm) => {
        if (state.operation === CRUD.CREATE) createUser(values)
        if (state.operation === CRUD.UPDATE) updateUserData(values)
    }

    const handleCreate = () => setCreate()
    const handleUpdate = (user: UserForm) => setUpdate(user)
    const handleDelete = (user: UserForm) => setDelete(user)
    const handleModalClose = () => setRead()

    const handleSwitchChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>, value: boolean, userID: string) => {
            const docRef = doc(db, COLLECTIONS.USERS, userID)
            try {
                await updateDoc(docRef, { isActive: value })
                success(MSG.DONE)
            } catch (err) {
                alertError(MSG.ERROR)
            }
        },
        [alertError, db, success]
    )

    const deleteUserData = async () => {
        const deleteUserFromAuth = httpsCallable(functions, 'deleteUser')
        loading(true)
        const id = (state.item as UserSelected).id
        try {
            await deleteUserFromAuth({ uid: id })
            await deleteDoc(doc(db, COLLECTIONS.USERS, id))
            success(MSG.USER_DELETED)
            handleModalClose()
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const createUser = async (values: UserForm) => {
        loading(true)
        const addUser = httpsCallable(functions, 'createUser')
        const { email, name, lastName, contractors, objects = [], lang, description } = values
        try {
            await addUser({
                name,
                lastName,
                email,
                contractors: contractors?.value ? [contractors?.value] : [],
                objects: objects.map(({ value }) => value),
                expoPushToken: '',
                isActive: true,
                lang: lang.value,
                notifyDeliverySoon: true,
                notifyTimeDeliveryChange: true,
                passwordNeedSet: true,
                description,
            })
            handleModalClose()
            success(MSG.USER_CREATED)
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const updateUserData = async (values: UserForm) => {
        loading(true)
        const updateUserEmail = httpsCallable(functions, 'updateUserEmail')
        const id = (state.item as UserSelected).id
        try {
            const { name, lastName, contractors, objects, email, description } = values
            await updateUserEmail({ uid: id, email })
            const ref = doc(db, COLLECTIONS.USERS, (state.item as UserSelected).id)
            await updateDoc(ref, {
                name,
                lastName,
                email,
                contractors: [contractors].map((c) => c?.value || ''),
                objects: objects.map(({ value }) => value),
                description,
            })
            handleModalClose()
            success(MSG.USER_UPDATED)
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const userData: Array<UserSelected> = useMemo(
        () =>
            users?.map((u) => {
                const contractor = u.contractors.map((c) =>
                    contractors?.find(({ id }) => id === c)
                )[0]
                const userObjects = u.objects?.map((docID) =>
                    objects?.find(({ id }) => docID === id)
                )

                return {
                    id: u.id || '',
                    fullName: `${u.name} ${u.lastName}`,
                    name: u.name,
                    email: u.email,
                    lastName: u.lastName,
                    objectID: userObjects?.map((o) => o?.name)?.join(', '),
                    description: u.description,
                    lang: {
                        name: LANGUAGES[u.lang].name,
                        value: LANGUAGES[u.lang].code,
                    },
                    contractors: {
                        name: contractor?.name,
                        value: contractor?.id,
                    },
                    objects: userObjects?.map((o) => ({
                        name: o?.name,
                        value: o?.id,
                    })),
                    isActive: u?.isActive,
                }
            }) || [],
        [users, contractors, objects]
    )

    const columns: Array<Column> = useMemo(
        () => [
            {
                Header: `${t('Imię i nazwisko')}`,
                accessor: 'fullName',
                size: 4,
                bold: true,
            },
            {
                Header: `${t('Firma')}`,
                accessor: 'contractors.name',
                size: 2,
            },
            {
                Header: `${t('Opis')}`,
                accessor: 'description',
                size: 2,
            },
            {
                Header: `${t('Aktywny')}`,
                accessor: 'isActive',
                size: 2,
                sortType: 'basic',
                Cell: (props: TableInstance) => {
                    const {
                        row: { original },
                        cell: { value },
                    } = props
                    return (
                        <Switch
                            onChange={(e: ChangeEvent<HTMLInputElement>, value: boolean) =>
                                handleSwitchChange(e, value, original?.id)
                            }
                            checked={Boolean(value)}
                        />
                    )
                },
            },
            {
                Header: `${t('Kod')}`,
                accessor: 'objectID',
                size: 2,
            },
        ],
        [handleSwitchChange, t]
    )

    useEffect(() => {
        if (contractorsError || objectsError || usersError) alertError(MSG.ERROR)
    }, [contractorsError, objectsError, usersError, alertError])

    const isLoading = usersLoading || contractorsLoading || objectsLoading

    return (
        <Page>
            <>
                <Heading
                    title={t('Użytkownicy')}
                    buttonText={t('Dodaj użytkownika')}
                    itemsCountText={`Ilość Użytkowników: ${users?.length || ''}`}
                    buttonOnClick={handleCreate}
                />
                {isLoading ? (
                    <Progress />
                ) : (
                    <Table
                        columns={columns}
                        data={userData}
                        pageSize={6}
                        onEdit={handleUpdate}
                        onDelete={handleDelete}
                    />
                )}
                <Modal
                    title={
                        state.operation === CRUD.CREATE ? 'Nowy użytkownik' : 'Edytuj użytkownika'
                    }
                    width={'700px'}
                    onClose={handleModalClose}
                    isOpen={state.operation === CRUD.CREATE || state.operation === CRUD.UPDATE}
                >
                    <FormUser
                        onSubmit={onSubmit}
                        onCancel={handleModalClose}
                        options={{
                            contractors: parseOptions(contractors),
                            objects: parseOptions(objects),
                            lang: Object.values(LANGUAGES).map(({ code, name }) => ({
                                value: code,
                                name,
                            })),
                        }}
                        initialValues={state.item as UserForm}
                        onSubmitText={
                            state.operation === CRUD.CREATE
                                ? 'Dodaj użytkownika'
                                : 'Zapisz użytkownika'
                        }
                        loading={state.loading}
                    />
                </Modal>
                <Dialog
                    open={state.operation === CRUD.DELETE}
                    onClose={handleModalClose}
                    title={t('Czy na pewno chcesz usunąć użytkownika?')}
                    submitText={t('Usuń')}
                    color={(theme as Theme).palette.error.main}
                    onSubmit={deleteUserData}
                    onSubmitDisable={state.loading}
                >
                    <span>
                        {t('Trwale zostaną usunięte dane oraz powiązania konta użytkownika ')}
                        {(state.item as UserSelected)?.fullName}
                    </span>
                </Dialog>
            </>
        </Page>
    )
}

export default Users
