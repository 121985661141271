import { ObjectForm } from 'objects'
import { useCallback, useReducer } from 'react'
import { UserForm } from 'users'
import { ReducerAction, StateManager } from '../../types'
import { ContractorForm } from '../../types/contractors'
import { CRUD } from '../../utils/constans'

const ACTIONS = {
    SET_OPERATION: 'SET_OPERATION',
    SET_LOADING: 'SET_LOADING',
}

const reducer = (state: StateManager, action: ReducerAction) => {
    switch (action.type) {
        case ACTIONS.SET_OPERATION:
            return {
                item: action.payload.item,
                operation: action.payload.operation,
                loading: action.payload.loading,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            }
        default:
            throw new Error()
    }
}

export const useCrudManager = () => {
    const [state, dispatch] = useReducer(reducer, {
        item: undefined,
        loading: false,
        operation: CRUD.READ,
    })

    return [
        state,
        useCallback(() => {
            dispatch({
                type: ACTIONS.SET_OPERATION,
                payload: { item: undefined, operation: CRUD.CREATE, loading: false },
            })
        }, []),
        useCallback((item: ContractorForm | ObjectForm | UserForm) => {
            dispatch({
                type: ACTIONS.SET_OPERATION,
                payload: { item, operation: CRUD.DELETE, loading: false },
            })
        }, []),
        useCallback((item: ContractorForm | ObjectForm | UserForm) => {
            dispatch({
                type: ACTIONS.SET_OPERATION,
                payload: { item, operation: CRUD.UPDATE, loading: false },
            })
        }, []),
        useCallback(() => {
            dispatch({
                type: ACTIONS.SET_OPERATION,
                payload: { item: undefined, operation: CRUD.READ, loading: false },
            })
        }, []),
        useCallback((isLoading: boolean) => {
            dispatch({
                type: ACTIONS.SET_LOADING,
                payload: { loading: isLoading },
            })
        }, []),
    ] as const
}
