import { useState } from 'react'

const useTooltip = () => {
    const [open, setOpen] = useState<boolean>(false)

    return {
        handleShowTooltip: () => setOpen(true),
        handleCloseTooltip: () => setOpen(false),
        open,
    }
}

export default useTooltip
