import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const StyledPage = styled(Box)`
    height: ${({ theme }) => `calc(100% - ${theme.utils.headerHeight}px)`};
    background: ${({ theme }) => theme.palette.grey['100']};
`
export const StyledPageContent = styled(Box)`
    max-width: 1250px;
    margin: 0 auto;
`
