import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { PATHS, ROUTES } from './routes'
import RequireAuth from './RequireAuth'
import NotFound from '../pages/NotFound'

export interface RouteObject {
    path: string
    title: string
    element: JSX.Element
    isPrivate: boolean
    children?: Array<RouteObject>
}

const Page = ({ route }: { route: RouteObject }) => {
    useEffect(() => {
        document.title = `${route.title} - Track and Trace by Scandinavian Express`
    })

    return route.isPrivate ? <RequireAuth>{route.element}</RequireAuth> : route.element
}

const renderRoutes = (routes: Array<RouteObject>) =>
    routes.map((route) => (
        <Route key={route.path} path={route.path} element={<Page route={route} />}>
            {route.children && renderRoutes(route.children)}
        </Route>
    ))

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Navigate replace to={`${PATHS.DASHBOARD}/${PATHS.OBJECTS}`} />}
                />
                <Route
                    path={PATHS.DASHBOARD}
                    element={<Navigate replace to={`${PATHS.DASHBOARD}/${PATHS.OBJECTS}`} />}
                />
                {ROUTES && renderRoutes(ROUTES)}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
