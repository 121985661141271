const useClipboard = () => {
    return {
        getSelection: (node: any) => {
            let range = document.createRange()
            range.selectNodeContents(node)
            let select = window.getSelection()
            select?.removeAllRanges()
            select?.addRange(range)
            return select
        },
        setClipboard: (text: string) => {
            const type = 'text/html'
            const blob = new Blob([text], { type })
            return [new ClipboardItem({ [type]: blob })]
        },
    }
}

export default useClipboard
