import { Backdrop, alpha } from '@mui/material'
import { styled } from '@mui/system'
import { styledMaterial } from '../../types/theme.d'

export const StyledModal = styled('div')`
    border-radius: ${({ theme }) => theme.shape.borderRadius + 'px'};
    max-width: ${({ width }: { width?: string }) => width || '500px'};
    background: ${({ theme }) => theme.palette.common.white};
    margin: ${({ theme }) => `${theme.spacing(5)} auto`};
`
export const StyledBackdrop = styledMaterial(Backdrop, {
    name: 'MuiModal',
    slot: 'Backdrop',
    overridesResolver: (props, styles) => {
        return styles.backdrop
    },
})(({ theme }) => ({ zIndex: -1, backgroundColor: alpha(theme.palette.common.black, 0.9) }))

export const StyledModalContent = styled('div')`
    padding: ${({ theme }) => theme.spacing(4)};
    border-radius: ${({ theme }) => theme.shape.borderRadius + 'px'};
    background-color: ${({ theme }) => theme.palette.grey[100]};
`
