import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiFilledInput: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: {
            padding: 0,
        },
    },
    variants: [],
}

export default MuiFilledInput
