import { styled } from '@mui/system'

export const StyledDashboard = styled('main')`
    display: flex;
    height: 100vh;
`

export const StyledDashboardContainer = styled('section')`
    width: 100%;
`
