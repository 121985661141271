import { signOut } from 'firebase/auth'
import React, { useCallback, useContext, useEffect } from 'react'
import { Navigate } from 'react-router'
import { FirebaseContext } from '../firebase'
import { useSnackbar } from '../hooks/useSnackbar'
import { Error } from '../types'
import { MSG } from '../utils/constans'
import { PATHS } from './routes'

const SignOut = () => {
    const { auth } = useContext(FirebaseContext)
    const [error, success] = useSnackbar()

    const signOutUser = useCallback(async () => {
        try {
            await signOut(auth)
            success(MSG.SIGNED_OUT)
        } catch (err) {
            error((err as Error).message)
        }
    }, [success, error, auth])

    useEffect(() => {
        signOutUser()
    }, [signOutUser])

    return <Navigate to={PATHS.SIGNIN} replace />
}

export default SignOut
