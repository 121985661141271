import { Popover, SvgIconProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { ReactElement } from 'react'
import { To, useMatch, useResolvedPath } from 'react-router-dom'
import { StyledNavLink } from './styles'

type Props = {
    to: To
    children?: string
    icon?: (active: boolean) => ReactElement<SvgIconProps>
}

const NavLink = ({ to, children, icon }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const resolved = useResolvedPath(to)
    const isActive = !!useMatch({ path: resolved.pathname, end: true })

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <StyledNavLink
                to={to}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {icon && icon(isActive)}
            </StyledNavLink>
            <Popover
                id="mouse-over-popover"
                PaperProps={{
                    sx: {
                        backgroundColor: grey['900'],
                    },
                }}
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </>
    )
}

export default NavLink
