import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiPopover: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
            color: theme.palette.common.white,
            padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        }),
    },
    variants: [],
}

export default MuiPopover
