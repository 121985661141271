import { Box, Grid, Transitions, Typography, TypographyProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/system'
import { css } from '@emotion/react'

export const StyledHeadingCol = styled(Grid)`
    text-transform: uppercase;
    font-size: 1.1rem;
    display: inline-flex;
    align-items: center;
    &:not(:first-of-type) {
        padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
    }
`

export const StyledRow = styled(Grid)`
    box-shadow: ${({ theme }) => theme.utils.shadows.main};
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
    margin: ${({ theme }) => `0 0 ${theme.spacing(2)}`};
    position: relative;
    overflow: hidden;
    min-height: 60px;
    &:hover {
        & > div {
            right: 0;
            opacity: 1;
        }
    }
`
export const StyledCol = styled(Grid)`
    &:not(:first-of-type) {
        color: ${({ theme }) => theme.palette.grey[600]};
        padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
    }
`

export const StyledButtonGroup = styled('div')`
    position: absolute;
    top: 0;
    right: -50px;
    background-color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    border-radius: ${({ theme }) =>
        `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`};
    height: 100%;
    opacity: 0;
    transition: ${({ theme }) =>
        `${(theme.transitions as Transitions).duration.complex}ms ${
            (theme.transitions as Transitions).easing.easeInOut
        }`};

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        box-shadow: ${({ theme }) => `-1px 2px 8px ${theme.palette.common.black}`};
        clip-path: inset(0px -8px 0px 0px);
        z-index: 2;
    }
`

export const StyledButton = styled('button')`
    height: 100%;
    border: 0;
    border-right: ${({ theme }) => `1px solid #f7f8f636`};
    background: none;
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
    transition: ${({ theme }) => (theme.transitions as Transitions).duration.shortest}ms;
    cursor: pointer;

    path {
        fill: ${({ theme }) => theme.palette.common.white};
    }
    svg {
        font-size: 2.2rem;
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: transparent;
        height: 30px;
        box-shadow: 0 -15px 0 0 ${({ theme }) => theme.palette.text.secondary};
        border-top-left-radius: 6px;
    }
    &::before {
        width: 10px;
        left: -20px;
        top: -10px;
        transform: rotate(90deg);
    }

    &::after {
        width: 10px;
        bottom: 0;
        left: -10px;
        transform: rotate(180deg);
    }
`
export const StyledHighlightedTypography = styled(Typography)`
    span {
        background: ${grey[200]};
        padding: ${({ theme }) => `${theme.spacing(1)} 0`};
    }
`

export const StyledHeadingRow = styled(Grid)`
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
    margin: ${({ theme }) => `0 0 ${theme.spacing(2)}`};
    position: sticky;
    top: 0;
    z-index: 3;
    &:after {
        content: '';
        z-index: -1;
        background: ${({ theme }) => `${theme.palette.grey[100]}`};
        position: absolute;
        top: 0;
        left: -10px;
        height: 100%;
        width: calc(100% + 20px);
    }
`

export const StyledTableWrapper = styled(Box)`
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`

interface TypographyHeadingRow extends TypographyProps {
    isActive: boolean
}

export const StyledTypographyHeading = styled(Typography, {
    shouldForwardProp: (props) => props !== 'isActive',
})<TypographyHeadingRow>`
    user-select: none;
    line-height: 18px;
    ${({ isActive, theme }) =>
        isActive &&
        css`
            color: ${theme.palette.text.primary};
            font-weight: 900;
        `}
`
