import { Backdrop } from '@mui/material'
import { onAuthStateChanged, User } from 'firebase/auth'
import React, { Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react'
import { FirebaseContext } from '../firebase'
import Progress from '../components/Progress'

type CurrentUser = User | null

type Props = {
    children: ReactNode
}

type UserAuthState = {
    user: CurrentUser
    loading: boolean
}

type UserAuthAction = {
    payload: UserAuthState
    type: string
}

export enum UserAuthActionKind {
    SET_USER = 'SET_USER',
}

const initialState = {
    user: null,
    loading: true,
}

const reducer = (state: UserAuthState, action: UserAuthAction) => {
    switch (action.type) {
        case UserAuthActionKind.SET_USER:
            return {
                user: action.payload.user,
                loading: action.payload.loading,
            }
        default:
            throw new Error()
    }
}

export let AuthContext = React.createContext<{
    auth: UserAuthState
    dispatch: Dispatch<UserAuthAction>
}>({ auth: initialState, dispatch: () => null })

const AuthProvider = ({ children }: Props) => {
    const [auth, dispatch] = useReducer(reducer, initialState)

    const { auth: authFirebase } = useContext(FirebaseContext)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authFirebase, (user: CurrentUser) => {
            dispatch({
                type: UserAuthActionKind.SET_USER,
                payload: { user, loading: false },
            })
        })

        return unsubscribe
    }, [authFirebase])

    if (auth.loading) {
        return (
            <Backdrop open={true} invisible={true}>
                <Progress />
            </Backdrop>
        )
    }

    return <AuthContext.Provider value={{ auth, dispatch }}>{children}</AuthContext.Provider>
}

export default AuthProvider
