import React, { useEffect, useRef } from 'react'
import { StyledInputPage, StyledPagination } from './styled'
import { IconButton, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'

type Props = {
    pageIndex: number
    pageLength: number
    previousPage: () => void
    canPreviousPage: boolean
    canNextPage: boolean
    nextPage: () => void
    gotoPage: (pageIndex: number) => void
}

const Pagination = ({
    pageIndex,
    pageLength,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    gotoPage,
}: Props) => {
    const { t } = useTranslation()
    const refInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (refInput.current) {
            refInput.current.value = pageIndex.toString()
        }
    }, [pageIndex])

    const handleChangePage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const page = e.target.value ? Number(e.target.value) - 1 : 0
        gotoPage(page)
    }

    return (
        <StyledPagination>
            {pageIndex > 0 && canPreviousPage && (
                <IconButton onClick={previousPage}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
            )}
            <StyledInputPage
                ref={refInput}
                type="number"
                defaultValue={pageIndex}
                min={1}
                max={pageLength}
                onChange={handleChangePage}
            />
            <Typography mx={2}>{t('z')}</Typography>
            <Typography>{pageLength}</Typography>
            {canNextPage && (
                <IconButton onClick={nextPage}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            )}
        </StyledPagination>
    )
}

export default Pagination
