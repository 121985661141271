import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiSelect: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        outlined: ({ theme }: { theme: Theme }) => ({}),
    },
    variants: [],
}

export default MuiSelect
