import React, { useState } from 'react'
import {
    Box,
    FormHelperText,
    InputLabel,
    FormControl,
    InputAdornment,
    IconButton,
} from '@mui/material'
import { StyledInput } from './styles'
import MultiSelect from '../MultiSelect'
import Select from '../Select'
import { Option } from '../../types'
import { Controller, useFormContext } from 'react-hook-form'
import VisibilityOutline from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutline from '@mui/icons-material/VisibilityOffOutlined'

type Props = {
    label: string
    pattern?: RegExp
    type: string
    name: string
    placeholder: string
    options?: Array<Option>
    disabledOptions?: Array<string>
    isRequired?: boolean
    passwordInput?: boolean
}

type MultiSelectOption = {
    name: string
    values: Array<Option>
    value: string
}

const Input = (props: Props) => {
    const {
        name,
        label,
        placeholder,
        type,
        options,
        disabledOptions,
        isRequired = false,
        pattern,
        passwordInput,
    }: Props = props
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const {
        control,
        register,
        formState: { errors },
    } = useFormContext()

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleClickShowPassword = () => setShowPassword((prev) => !prev)

    const passwordAdornment = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <VisibilityOffOutline /> : <VisibilityOutline />}
            </IconButton>
        </InputAdornment>
    )

    return (
        <Box mb={3}>
            <FormControl>
                <InputLabel shrink htmlFor={`input-${name}`}>
                    {label}
                </InputLabel>
                {type === 'multiselect' ? (
                    <Controller
                        name={name}
                        control={control}
                        rules={{ required: isRequired }}
                        render={({ field }) => (
                            <MultiSelect
                                {...field}
                                options={options as Array<MultiSelectOption>}
                                disabledOptions={disabledOptions}
                                placeholder={placeholder}
                            />
                        )}
                    />
                ) : type === 'select' ? (
                    <Controller
                        name={name}
                        control={control}
                        rules={{ required: isRequired }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={placeholder}
                                options={options as Array<Option>}
                                disabledOptions={disabledOptions}
                            />
                        )}
                    />
                ) : (
                    <>
                        <StyledInput
                            inputComponent={type === 'textarea' ? type : 'input'}
                            id={`input-${name}`}
                            placeholder={placeholder}
                            inputProps={{ type: showPassword ? 'text' : type, name }}
                            fullWidth
                            {...register(name, { required: isRequired, pattern })}
                            autoComplete={type === 'password' ? 'current-password' : ''}
                            endAdornment={passwordInput && passwordAdornment}
                        />
                    </>
                )}

                {errors[name] && (
                    <FormHelperText id="component-error-text">Dane niepoprawne</FormHelperText>
                )}
            </FormControl>
        </Box>
    )
}

export default Input
