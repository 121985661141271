import React, { forwardRef } from 'react'
import Checkbox from '@mui/material/Checkbox'
import {
    StyledCloseIcon,
    StyledMultiInput,
    StyledOption,
    StyledSelectedItem,
} from '../Input/styles'

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Theme, AutocompleteRenderOptionState } from '@mui/material'
import { MultiSelectOption } from '../../types'

type Props = {
    placeholder?: string
    options: Array<MultiSelectOption>
    disabledOptions?: Array<string>
    onChange: (values: Array<MultiSelectOption>) => void
    value: Array<MultiSelectOption>
}

const MultiSelect = forwardRef(
    ({ placeholder, options = [], disabledOptions = [], onChange, value = [] }: Props, ref) => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="large" />
        const checkedIcon = (
            <CheckBoxIcon
                fontSize="large"
                sx={{ fill: (theme: Theme) => theme.palette.text.primary }}
            />
        )

        const CustomInput = (params: AutocompleteRenderInputParams) => (
            <StyledMultiInput variant="standard" placeholder={placeholder} {...params} />
        )

        const CustomOptionRender = (
            props: object,
            option: MultiSelectOption,
            { selected }: AutocompleteRenderOptionState
        ) => (
            <StyledOption {...props}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.name}
            </StyledOption>
        )

        const CustomTagRender = (tags: Array<MultiSelectOption>, getTagProps: Function) => {
            const handleClick = (tag: MultiSelectOption, i: number) => {
                const { onDelete } = getTagProps({ index: i })
                onDelete(tag.name)
            }

            return tags.map((tag, i) => (
                <StyledSelectedItem key={tag.name}>
                    {tag.name}
                    <StyledCloseIcon onClick={() => handleClick(tag, i)} />
                </StyledSelectedItem>
            ))
        }

        const handleOnChange = (e: React.SyntheticEvent, values: Array<MultiSelectOption>) =>
            onChange(values)

        return (
            <Autocomplete
                multiple
                isOptionEqualToValue={(option, value) => value.value === option.value}
                id="checkboxes-tags-demo"
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => disabledOptions?.includes(option.value)}
                onChange={handleOnChange}
                renderOption={CustomOptionRender}
                renderInput={CustomInput}
                renderTags={CustomTagRender}
                ref={ref}
                value={value}
            />
        )
    }
)

export default MultiSelect
