import { Button, Divider, alpha } from '@mui/material'
import { Box, Theme } from '@mui/system'
import React, { MouseEventHandler } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

type Props = {
    onSubmitText?: string
    onCancel?: MouseEventHandler<HTMLAnchorElement>
    onSubmitDisable?: boolean
}

const FormFooter = ({ onSubmitText, onCancel = () => ({}), onSubmitDisable }: Props) => {
    return (
        <>
            <Divider
                sx={{
                    mt: 5,
                    mb: 5,
                    borderColor: (theme: Theme) => alpha(theme.palette.text.secondary, 0.2),
                }}
            />

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                    variant="text"
                    startIcon={<CloseIcon fontSize={'large'} />}
                    onClick={onCancel}
                    href=""
                >
                    Anuluj
                </Button>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    type="submit"
                    disabled={onSubmitDisable}
                >
                    {onSubmitText}
                </Button>
            </Box>
        </>
    )
}

export default FormFooter
