import { styled } from '@mui/system'

export const StyledPagination = styled('div')`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
`

export const StyledInputPage = styled('input')`
    font-size: 14px;
    letter-spacing: -0.025em;
    color: ${({ theme }) => theme.palette.common.black};
    text-align: center;
    min-width: 40px;
    flex-basis: 40px;
    border-radius: 5px;
    height: 30px;
    padding: 6px;
    border: none;
    outline: none;
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`
