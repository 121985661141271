import React from 'react'
import { Outlet } from 'react-router-dom'
import SearchProvider from '../../context/SearchProvider'
import Header from '../../modules/Header'
import Sidebar from '../../modules/Sidebar'
import { StyledDashboard, StyledDashboardContainer } from './styles'

const Dashboard = () => {
    return (
        <SearchProvider>
            <StyledDashboard>
                <Sidebar />
                <StyledDashboardContainer>
                    <Header />
                    <Outlet />
                </StyledDashboardContainer>
            </StyledDashboard>
        </SearchProvider>
    )
}

export default Dashboard
