import { Contractor } from 'contractors'
import { ObjectData } from 'objects'
import { Error } from '../../types'
import { User } from '../../types/users'
import { MSG } from '../constans'

export const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,64}$/

// passwordPattern:
// ^ - Beginning of string
// (?=.*[a-z]) - A positive look-ahead to require a lowercase letter
// (?=.*[A-Z]) - A positive look-ahead to require an uppercase letter
// (?=.*[0-9]) - A positive look-ahead to require a digit
// REMOVED (?=.*[ -/:-@\[-{-~])` - A positive look-ahead to require a special character
// .{6,64} - Any character (but a newline), 6 to 64 occurrences
// $ - End of string.

export const parseMessage = ({ code, message }: Error) => {
    const errType = code.split('/').reverse()[0].split(')')[0].replace(/-/g, '_').toUpperCase()

    if (errType === 'UNKNOWN') return message
    return MSG[errType] || `${code}: ${message}`
}

export const parseOptions = (options: Array<Contractor | ObjectData> | undefined) => {
    return options?.map(({ id, name }) => ({ value: id || '', name }))
}

export const parseOption = (options: Contractor | ObjectData | User) => {
    return {
        name: options.name,
        value: options.id,
    }
}
