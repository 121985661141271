import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../../../components/Page'
import Heading from '../../../modules/Heading'
import Table from '../../../modules/Table'
import { FirebaseContext } from '../../../firebase'
import Modal from '../../../components/Modal'
import { COLLECTIONS, CRUD, MSG } from '../../../utils/constans'
import { useCrudManager } from '../../../hooks/useCrudManager'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { addDoc, arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { objectConverter, userConverter } from '../../../firebase/converters'
import { parseMessage } from '../../../utils/helpers'
import Progress from '../../../components/Progress'
import { ObjectData, ObjectForm, ObjectSelected } from '../../../types/objects'
import { Column } from 'react-table'
import Dialog from '../../../components/Dialog'
import theme from '../../../theme'
import type { Theme } from '@mui/system'
import FormObject from '../../../modules/FormObject'
import { Error } from '../../../types'
import UserStatistics from '../../../modules/UserStatistics'
import { User } from 'users'

const Objects = () => {
    const { t } = useTranslation()
    const { db } = useContext(FirebaseContext)
    const [state, setCreate, setDelete, setUpdate, setRead, loading] = useCrudManager()
    const [alertError, success] = useSnackbar()
    const [chosenObjectStats, setChosenObjectStats] = useState<ObjectSelected>()

    const queryObjects = collection(db, COLLECTIONS.OBJECTS).withConverter(objectConverter)
    const [objects, objectsLoading, objectsError] = useCollectionData(queryObjects)

    const queryUsers = collection(db, COLLECTIONS.USERS).withConverter(userConverter)
    const [users, usersLoading, usersError] = useCollectionData(queryUsers)

    const onSubmit = (values: ObjectForm) => {
        if (state.operation === CRUD.CREATE) createObject(values)
        if (state.operation === CRUD.UPDATE) updateObject(values)
    }

    const handleCreate = () => setCreate()
    const handleUpdate = (item: ObjectForm) => setUpdate(item)
    const handleSetObjectStats = (item?: ObjectSelected) => setChosenObjectStats(item)
    const handleDelete = (item: ObjectForm) => setDelete(item)
    const handleModalClose = () => setRead()

    const getUsersByObject = useCallback(
        (id?: string) => users?.filter(({ objects }) => !!objects.find((docID) => docID === id)),
        [users]
    )

    const createObject = async (values: ObjectForm) => {
        loading(true)
        const { name, localisation, objectID, users } = values
        try {
            const { id: docID } = await addDoc(collection(db, COLLECTIONS.OBJECTS), {
                name,
                localisation,
                objectID,
            })
            users?.forEach(async ({ value: userID }) => {
                if (!userID) return
                const userRef = doc(db, COLLECTIONS.USERS, userID)
                await updateDoc(userRef, {
                    objects: arrayUnion(docID),
                })
            })
            handleModalClose()
            success(MSG.USER_CREATED)
        } catch (err) {
            alertError(err as string)
        } finally {
            loading(false)
        }
    }

    const updateObject = async (values: ObjectForm) => {
        loading(true)
        const { id: docID, users, ...fields } = values as ObjectSelected
        const newUsers = users?.map(({ value }) => value)
        const currentUsers = (state.item as ObjectForm).users?.map(({ value }) => value)
        const toDelete = currentUsers?.filter((currentUserID) => !newUsers?.includes(currentUserID))
        const toAdd = newUsers?.filter((currentUserID) => !currentUsers?.includes(currentUserID))

        try {
            const objectsRef = doc(db, COLLECTIONS.OBJECTS, docID)
            await updateDoc(objectsRef, {
                name: fields.name,
                objectID: fields.objectID,
                localisation: fields.localisation,
            })

            toDelete?.forEach(async (userID) => {
                if (!userID) return
                const userRef = doc(db, COLLECTIONS.USERS, userID)
                await updateDoc(userRef, {
                    objects: arrayRemove(docID),
                })
            })

            toAdd?.forEach(async (userID) => {
                if (!userID) return
                const userRef = doc(db, COLLECTIONS.USERS, userID)
                await updateDoc(userRef, {
                    objects: arrayUnion(docID),
                })
            })
            handleModalClose()
            success(MSG.OBJECT_UPDATED)
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const deleteObject = async () => {
        loading(true)
        const docID = (state.item as ObjectData).id
        try {
            const users = getUsersByObject(docID)?.map(({ id }) => id)

            await deleteDoc(doc(db, COLLECTIONS.OBJECTS, docID))
            users?.forEach(async (userID) => {
                if (!userID) return
                const userRef = doc(db, COLLECTIONS.USERS, userID)
                await updateDoc(userRef, {
                    objects: arrayRemove(docID),
                })
            })
            handleModalClose()
            success(MSG.OBJECT_DELETED)
        } catch (err) {
            alertError(parseMessage(err as Error))
        } finally {
            loading(false)
        }
    }

    const objectsData: Array<ObjectForm> = useMemo(
        () =>
            objects?.map((o) => {
                return {
                    id: o.id,
                    name: o.name,
                    objectID: o.objectID,
                    localisation: o.localisation,
                    users: getUsersByObject(o.id)?.map(({ name, id: userID, lastName }) => ({
                        name: `${name} ${lastName}`,
                        value: userID,
                    })),
                }
            }) || [],
        [objects, getUsersByObject]
    )

    const columns: Array<Column> = useMemo(
        () => [
            {
                Header: `${t('Obiekt')}`,
                accessor: 'name',
                bold: true,
                size: 4,
            },
            {
                Header: `${t('Lokalizacja')}`,
                accessor: 'localisation',
                size: 4,
            },
            {
                Header: `${t('Użytkownicy')}`,
                accessor: 'users.length',
                size: 2,
                disableGlobalFilter: true,
            },
            {
                Header: `${t('Kod')}`,
                accessor: 'objectID',
                size: 2,
            },
        ],
        [t]
    )

    const usersFromObject = useMemo(() => {
        return getUsersByObject(chosenObjectStats?.id) as User[]
    }, [chosenObjectStats, getUsersByObject])

    useEffect(() => {
        if (objectsError || usersError) alertError(objectsError?.message || usersError?.message)
    }, [objectsError, usersError, alertError])

    const isLoading = usersLoading || objectsLoading

    return (
        <Page>
            <>
                <Heading
                    title={t('Obiekty')}
                    buttonText={t('Dodaj obiekt')}
                    itemsCountText={`Ilość Obiektów: ${objects?.length || ''}`}
                    buttonOnClick={handleCreate}
                />
                {isLoading ? (
                    <Progress />
                ) : (
                    <Table
                        columns={columns}
                        data={objectsData}
                        pageSize={6}
                        onEdit={handleUpdate}
                        onDelete={handleDelete}
                        onGenerateStats={handleSetObjectStats}
                    />
                )}
                <Modal
                    title={state.operation === CRUD.CREATE ? 'Nowy obiekt' : 'Edytuj obiekt'}
                    onClose={handleModalClose}
                    isOpen={state.operation === CRUD.CREATE || state.operation === CRUD.UPDATE}
                >
                    <FormObject
                        onSubmit={onSubmit}
                        onCancel={handleModalClose}
                        options={{
                            users: users?.map(({ id, name, lastName }) => ({
                                value: id || '',
                                name: `${name} ${lastName}`,
                            })),
                        }}
                        onSubmitText={
                            state.operation === CRUD.CREATE ? 'Dodaj obiekt' : 'Zapisz obiekt'
                        }
                        loading={state.loading}
                        initialValues={state.item as ObjectForm}
                    />
                </Modal>
                <Dialog
                    open={state.operation === CRUD.DELETE}
                    onClose={handleModalClose}
                    title={t('Czy na pewno chcesz usunąć obiekt?')}
                    submitText={t('Usuń')}
                    color={(theme as Theme).palette.error.main}
                    onSubmit={deleteObject}
                    onSubmitDisable={state.loading}
                >
                    <span>{t('Trwale zostaną usunięte dane oraz powiązania konta obiekt ')}</span>
                </Dialog>
                <Modal
                    title={`Statystyki użytkowników dla obiektu ${chosenObjectStats?.objectID} - ostatnie 90 dni`}
                    onClose={() => handleSetObjectStats(undefined)}
                    isOpen={Boolean(chosenObjectStats)}
                >
                    {chosenObjectStats ? <UserStatistics data={usersFromObject} /> : <div></div>}
                </Modal>
            </>
        </Page>
    )
}

export default Objects
