import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import {
    StyledButtonGroup,
    StyledCol,
    StyledHeadingCol,
    StyledHeadingRow,
    StyledButton,
    StyledRow,
    StyledHighlightedTypography,
    StyledTableWrapper,
    StyledTypographyHeading,
} from './styles'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import {
    useTable,
    useSortBy,
    usePagination,
    Column,
    TableInstance,
    useFilters,
    useGlobalFilter,
} from 'react-table'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Pagination from '../../components/Pagination'
import { useTranslation } from 'react-i18next'
import { UserSelected } from '../../types/users'
import { Contractor } from '../../types/contractors'
import { ObjectForm } from 'objects'
import useSearch from '../../hooks/useSearch'
import ShowChartIcon from '@mui/icons-material/ShowChart'
type Props = {
    columns: Array<Column>
    data: Array<ObjectForm> | Array<UserSelected> | Array<Contractor>
    pageSize: number
    onGenerateStats?: Function
    onEdit: Function
    onDelete: Function
}

function escapeRegExp(string: string): string {
    const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
    const reHasRegExpChar = RegExp(reRegExpChar.source)
    return string && reHasRegExpChar.test(string)
        ? string.replace(reRegExpChar, '\\$&')
        : string || ''
}

const highlightText = (value: string, searchValue: string): string => {
    const searchRegex = new RegExp(`(${escapeRegExp(searchValue)})`, 'ig')
    return value?.replace(searchRegex, '<span>$1</span>')
}

const Table = ({ columns, data, pageSize, onEdit, onDelete, onGenerateStats }: Props) => {
    const { t } = useTranslation()
    const { searchValue } = useSearch()
    const tableBodyRef = useRef<HTMLInputElement>()
    const tableFooterRef = useRef<HTMLInputElement>()
    const defaultColumn = useMemo(
        () => ({
            Cell: ({
                cell: { value },
                state: { globalFilter },
                column: { bold, disableGlobalFilter },
            }: TableInstance) => {
                const highlightedText = !disableGlobalFilter
                    ? highlightText(value, globalFilter)
                    : value
                return (
                    <StyledHighlightedTypography
                        fontWeight={bold && 600}
                        dangerouslySetInnerHTML={{
                            __html: highlightedText,
                        }}
                    />
                )
            },
        }),
        []
    )
    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        setGlobalFilter,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            autoResetExpanded: false,
            autoResetSortBy: false,
            autoResetPage: false,
            initialState: {
                pageSize,
            },
            defaultColumn,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    )

    useEffect(() => {
        setGlobalFilter(searchValue || undefined)
    }, [searchValue, setGlobalFilter])

    useLayoutEffect(() => {
        let stylesTable = tableBodyRef?.current?.style
        if (stylesTable)
            stylesTable.maxHeight = `calc(100vh - (${tableBodyRef?.current?.offsetTop}px + ${tableFooterRef?.current?.offsetHeight}px))`
    }, [])

    if (!page.length) {
        return (
            <Box display="flex" justifyContent="center">
                <Typography>{t('Brak danych')}</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ margin: '0 -10px' }}>
            <StyledTableWrapper {...getTableProps()} ref={tableBodyRef}>
                {headerGroups.map((headerGroup) => (
                    <StyledHeadingRow {...headerGroup.getHeaderGroupProps()} container>
                        {headerGroup.headers.map((column) => (
                            <StyledHeadingCol
                                {...column.getHeaderProps([column.getSortByToggleProps()])}
                                item
                                xs={column.size}
                            >
                                <StyledTypographyHeading isActive={column.isSorted} variant="h5">
                                    {column.render('Header')}
                                </StyledTypographyHeading>
                                {column.isSorted &&
                                    (column.isSortedDesc ? (
                                        <KeyboardArrowDownIcon />
                                    ) : (
                                        <KeyboardArrowUpIcon />
                                    ))}
                            </StyledHeadingCol>
                        ))}
                    </StyledHeadingRow>
                ))}
                {page.map((row) => {
                    prepareRow(row)
                    const rowProps = row.getRowProps()
                    return (
                        <StyledRow {...rowProps} container alignItems="center">
                            {row.cells.map((cell) => (
                                <StyledCol {...cell.getCellProps()} item xs={cell.column.size}>
                                    {cell.render('Cell')}
                                </StyledCol>
                            ))}
                            <StyledButtonGroup>
                                {onGenerateStats && (
                                    <StyledButton
                                        onClick={() =>
                                            onGenerateStats(
                                                row.original as UserSelected | Contractor
                                            )
                                        }
                                    >
                                        <ShowChartIcon />
                                    </StyledButton>
                                )}
                                <StyledButton
                                    onClick={() =>
                                        onEdit(row.original as UserSelected | Contractor)
                                    }
                                >
                                    <EditIcon />
                                </StyledButton>
                                <StyledButton
                                    onClick={() =>
                                        onDelete(row.original as UserSelected | Contractor)
                                    }
                                >
                                    <CloseIcon />
                                </StyledButton>
                            </StyledButtonGroup>
                        </StyledRow>
                    )
                })}
            </StyledTableWrapper>
            <Box
                display="flex"
                justifyContent="flex-end"
                ref={tableFooterRef}
                sx={{ padding: '10px 10px 0 0' }}
            >
                <Pagination
                    pageIndex={pageIndex + 1}
                    pageLength={pageOptions.length === 0 ? 1 : pageOptions.length}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                />
            </Box>
        </Box>
    )
}

export default Table
