import React, { ChangeEvent, useMemo, useState } from 'react'
import useDebounce from '../hooks/useDebounce'

interface Props {
    children: JSX.Element
}

export const SearchContext = React.createContext<{
    inputSearchValue: string
    handleChangeInputSearchValue?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeItemsCount: (value: number) => void
    searchValue: string
    resetInputSearchValue: () => void
    itemsCount?: number
}>({
    inputSearchValue: '',
    itemsCount: undefined,
    searchValue: '',
    resetInputSearchValue: () => {},
    handleChangeItemsCount: () => {},
})

const SearchProvider = ({ children }: Props) => {
    const [inputSearchValue, setInputSearchValue] = useState('')
    const [itemsCount, setItemsCount] = useState<number | undefined>(undefined)
    const searchDebouncedValue = useDebounce(inputSearchValue, 200)

    const handleChangeInputSearchValue = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => setInputSearchValue(e.target.value)

    const handleChangeItemsCount = (value: number) => setItemsCount(value)
    const resetInputSearchValue = () => setInputSearchValue('')

    const searchValue = useMemo(() => {
        if (searchDebouncedValue.length < 3) return ''
        return searchDebouncedValue
    }, [searchDebouncedValue])

    return (
        <SearchContext.Provider
            value={{
                handleChangeInputSearchValue,
                handleChangeItemsCount,
                inputSearchValue,
                itemsCount,
                searchValue,
                resetInputSearchValue,
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider
