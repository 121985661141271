import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiFormControl: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: {
            width: '100%!important',
        },
    },
    variants: [],
}

export default MuiFormControl
