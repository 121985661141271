import React from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import { CssBaseline, ThemeProvider } from '@mui/material'
import Router from './routes'
import theme from './theme'
import i18n from './utils/i18n'
import { firebaseData, FirebaseContext } from './firebase'
import { SnackbarProvider } from 'notistack'
import AuthProvider from './context/AuthProvider'
import { createRoot } from 'react-dom/client'
import '@fontsource/barlow' // Defaults to weight 400.

// After
function App() {
    return (
        <React.StrictMode>
            <SnackbarProvider maxSnack={3} style={{ fontSize: '1.4rem' }}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <FirebaseContext.Provider value={firebaseData}>
                            <AuthProvider>
                                <Router />
                            </AuthProvider>
                        </FirebaseContext.Provider>
                    </ThemeProvider>
                </I18nextProvider>
            </SnackbarProvider>
        </React.StrictMode>
    )
}

const container = document.getElementById('root')
const root = container && createRoot(container)
root?.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
