import * as React from 'react'
import { Modal as BasicModal, Box, Typography, IconButton } from '@mui/material'
import { StyledBackdrop, StyledModal, StyledModalContent } from './styles'
import CloseIcon from '@mui/icons-material/Close'

type ModalProps = {
    children: JSX.Element
    title: string
    width?: string
    onClose: () => void
    isOpen: boolean
}

const Modal = ({ children, title, onClose, isOpen, width }: ModalProps) => (
    <BasicModal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={StyledBackdrop}
        sx={{
            overflow: 'scroll',
        }}
    >
        <StyledModal width={width}>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={5}
                py={3}
            >
                <Typography variant="h3" sx={{ lineHeight: '2.7rem', fontSize: '1.3rem' }}>
                    {title}
                </Typography>
                <IconButton aria-label="delete" onClick={onClose}>
                    <CloseIcon fontSize={'large'} />
                </IconButton>
            </Box>
            <StyledModalContent>{children}</StyledModalContent>
        </StyledModal>
    </BasicModal>
)

export default Modal
