// @ts-ignore
import { useSnackbar as useSnackbarNotistack } from 'notistack'
import { useCallback } from 'react'

export const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbarNotistack()

    return [
        useCallback(
            (message?: string) => {
                enqueueSnackbar(message, {
                    variant: 'error',
                })
            },
            [enqueueSnackbar]
        ),
        useCallback(
            (message?: string) => {
                enqueueSnackbar(message, {
                    variant: 'success',
                })
            },
            [enqueueSnackbar]
        ),
        useCallback(() => {
            closeSnackbar()
        }, [closeSnackbar]),
    ]
}
