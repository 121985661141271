import { OverridesStyleRules } from '@mui/material/styles/overrides'

import { Theme, alpha } from '@mui/system'

const MuiButton: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            fontSize: '1.4rem',
            fontWeight: 500,
            textTransform: 'initial',
            padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
        }),
        contained: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        }),
        text: ({ theme }: { theme: Theme }) => ({
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: alpha(theme.palette.text.primary, 0.035),
            },
        }),
    },
    variants: [],
}

export default MuiButton
