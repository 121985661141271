import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from '../i18n/pl.json';

i18n.use(initReactI18next).init({
    fallbackLng: ['pl'],
    lng: 'pl',
    debug: false,
    resources: {
        pl: { translation: pl },
    },
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
