import React from 'react'
//PUBLIC pages
import SignIn from '../pages/SignIn'
import SignOut from './SignOut'

//PRIVATE pages
import Users from '../pages/Dashboard/Users'
import Dashboard from '../pages/Dashboard'
import Objects from '../pages/Dashboard/Objects'
import Contractors from '../pages/Dashboard/Contractors'

export const PATHS = {
    SIGNIN: '/sign-in',
    SIGNOUT: '/sign-out',
    DASHBOARD: '/dashboard',
    OBJECTS: 'objects',
    USERS: 'users',
    CONTRACTORS: 'contractors',
}

export const ROUTES = [
    {
        path: PATHS.SIGNIN,
        title: `LOGOWANIE`,
        element: <SignIn />,
        isPrivate: false,
    },
    {
        path: PATHS.SIGNOUT,
        title: 'WYLOGOWANIE',
        element: <SignOut />,
        isPrivate: false,
    },
    {
        path: PATHS.DASHBOARD,
        title: 'DASHBOARD',
        element: <Dashboard />,
        isPrivate: true,
        children: [
            {
                path: PATHS.OBJECTS,
                title: 'OBIEKTY',
                element: <Objects />,
                isPrivate: true,
            },
            {
                path: PATHS.USERS,
                title: 'UŻYTKOWNICY',
                element: <Users />,
                isPrivate: true,
            },
            {
                path: PATHS.CONTRACTORS,
                title: 'KONTRAHENCI',
                element: <Contractors />,
                isPrivate: true,
            },
        ],
    },
]
