import { Box, Button, Divider, Typography } from '@mui/material'
import { Theme } from '@mui/system'
import React from 'react'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { useNavigate } from 'react-router-dom'

type Props = {}

const NotFound = (props: Props) => {
    const navigate = useNavigate()
    const handleBackButtonClick = () => navigate(-1)
    return (
        <Box
            sx={{
                height: '100vh',
                background: (theme: Theme) => `${theme.palette.grey[100]}`,
            }}
        >
            <Box
                display={'flex'}
                flexDirection="column"
                sx={{
                    padding: (theme: Theme) => `${theme.spacing(10)} 0 0 0`,
                }}
            >
                <Typography variant={'h1'} textAlign="center">
                    404
                </Typography>
                <Divider
                    sx={{ margin: (theme: Theme) => `${theme.spacing(2)} 0 ${theme.spacing(1)} 0` }}
                />
                <Typography variant={'h5'} textAlign="center">
                    Nie znaleziono strony
                </Typography>
            </Box>
            <Button
                onClick={handleBackButtonClick}
                variant="text"
                sx={{ display: 'flex', margin: '0 auto' }}
                startIcon={<KeyboardBackspaceOutlinedIcon />}
            >
                Wróć
            </Button>
        </Box>
    )
}

export default NotFound
