import { Theme } from '@mui/system'
import theme from '../theme'
import { emailPattern, passwordPattern } from './helpers'

import { InputFields } from 'forms'

export const TOKEN_KEY = 'auth_token'

export const FORM_SIGN_IN = [
    {
        name: 'email',
        type: 'text',
        label: 'Email',
        pattern: emailPattern,
        placeholder: 'Adres Email',
        isRequired: true,
    },
    {
        name: 'password',
        type: 'password',
        label: 'Hasło',
        pattern: passwordPattern,
        placeholder: 'Hasło',
        isRequired: true,
    },
]

export const FORM_OBJECT: Array<InputFields> = [
    {
        name: 'name',
        type: 'text',
        label: 'Nazwa obiektu',
        placeholder: 'Wpisz nazwę obiektu',
        isRequired: true,
    },
    {
        name: 'localisation',
        type: 'text',
        label: 'Lokalizacja',
        placeholder: 'Wpisz adres obiektu',
        isRequired: true,
    },
    {
        name: 'objectID',
        type: 'text',
        label: 'CODE',
        placeholder: 'Wpisz 5-ci literowy kod obiektu, zgodnie ze SPEED',
        isRequired: true,
    },
    {
        name: 'users',
        type: 'multiselect',
        placeholder: 'Dane uzytkownika...',
        label: 'Osoby przypisane do projektu',
        isRequired: false,
    },
]

export const FORM_USER: Array<InputFields> = [
    {
        name: 'name',
        type: 'text',
        label: 'Imię',
        placeholder: 'Wpisz imię użytkownika',
        isRequired: true,
    },
    {
        name: 'lastName',
        type: 'text',
        label: 'Nazwisko',
        placeholder: 'Wpisz nazwisko użytkownika',
        isRequired: true,
    },
    {
        name: 'contractors',
        type: 'select',
        label: 'Firma',
        placeholder: 'Wybierz firmę z listy',
        isRequired: false,
    },
    {
        name: 'email',
        type: 'text',
        label: 'Email',
        pattern: emailPattern,
        placeholder: 'Adres Email',
        isRequired: true,
    },
    {
        name: 'lang',
        type: 'select',
        label: 'Język',
        placeholder: 'Wybierz język z listy',
        isRequired: true,
    },
    {
        name: 'description',
        type: 'textarea',
        label: 'Opis',
        placeholder: 'Napisz coś...',
        isRequired: false,
    },
    {
        name: 'objects',
        type: 'multiselect',
        label: 'Przypisane obiekty',
        placeholder: 'Obiekt...',
        isRequired: false,
    },
]

export const FORM_CONTRACTOR: Array<InputFields> = [
    {
        name: 'name',
        type: 'text',
        label: 'Firma',
        placeholder: 'Wpisz nazwę kontrahenta',
        isRequired: true,
    },
    {
        name: 'contractorID',
        type: 'text',
        label: 'ID Speed',
        placeholder: 'ID kontrahenta zgodne ze SPEED',
        isRequired: true,
    },
]

export const snackbarStyles = {
    success: { backgroundColor: (theme as Theme).palette.primary.main },
    error: { backgroundColor: 'blue' },
    warning: { backgroundColor: 'green' },
    info: { backgroundColor: 'yellow' },
}

export const CRUD = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
}

export const ALERTS = {
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
}

export const MSG: { [key: string]: string } = {
    USER_CREATED: 'Użytkownik został utworzony',
    OBJECT_CREATED: 'Obiekt został utworzony',
    CONTRACTOR_CREATED: 'Kontrahent został utworzony',
    USER_UPDATED: 'Użytkownik zaktualizowany',
    OBJECT_UPDATED: 'Obiekt zaktualizowany',
    CONTRACTOR_UPDATED: 'Kontrahent zaktualizowany',
    USER_DELETED: 'Użytkownik został usunięty',
    OBJECT_DELETED: 'Obiekt został usunięty',
    CONTRACTOR_DELETED: 'Kontrahent został usunięty',
    ALREADY_EXISTS: 'Email został już wykorzystany',
    ERROR: 'Coś poszło nie tak...',
    DONE: 'Gotowe',
    WRONG_PASSWORD: 'Niepoprawne dane użytkownika',
    SIGNED_OUT: 'Wylogowano',
}

export const COLLECTIONS: { [key: string]: string } = {
    USERS: 'users',
    CONTRACTORS: 'contractors',
    OBJECTS: 'objects',
}

export const LANGUAGES: { [key: string]: { [key: string]: string } } = {
    PL: { code: 'PL', name: 'Polski' },
    EN: { code: 'EN', name: 'Angielski' },
}
