import { alpha } from '@mui/material'
import React from 'react'
import theme from '../../theme'

interface Props {
    icon: string
    color?: string
    width?: string
    height?: string
}

const Icon = ({ icon, color, width, height }: Props) => {
    switch (icon) {
        case 'object':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width || '1.6rem'}
                    height={height}
                    viewBox="0 0 17 17.5"
                >
                    <g
                        data-name="Group 12"
                        fill="#fff"
                        stroke={color || alpha(theme.palette.text.primary, 0.5)}
                        strokeWidth="1.5"
                    >
                        <g data-name="Group 10">
                            <g data-name="Rectangle 36">
                                <rect width="15" height="10" rx="2" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="13.5"
                                    height="8.5"
                                    rx="1.25"
                                    fill="none"
                                />
                            </g>
                            <g data-name="Rectangle 37" transform="translate(3 3)">
                                <rect width="4" height="4" rx="1" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="2.5"
                                    height="2.5"
                                    rx=".25"
                                    fill="none"
                                />
                            </g>
                            <g data-name="Rectangle 38" transform="translate(8 3)">
                                <rect width="4" height="4" rx="1" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="2.5"
                                    height="2.5"
                                    rx=".25"
                                    fill="none"
                                />
                            </g>
                        </g>
                        <g data-name="Group 13">
                            <g data-name="Rectangle 36" transform="translate(2 7.5)">
                                <rect width="15" height="10" rx="2" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="13.5"
                                    height="8.5"
                                    rx="1.25"
                                    fill="none"
                                />
                            </g>
                            <g data-name="Rectangle 37" transform="translate(5 10.5)">
                                <rect width="4" height="4" rx="1" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="2.5"
                                    height="2.5"
                                    rx=".25"
                                    fill="none"
                                />
                            </g>
                            <g data-name="Rectangle 38" transform="translate(10 10.5)">
                                <rect width="4" height="4" rx="1" stroke="none" />
                                <rect
                                    x=".75"
                                    y=".75"
                                    width="2.5"
                                    height="2.5"
                                    rx=".25"
                                    fill="none"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            )
        case 'user':
            return (
                <svg
                    data-name="pobrane (5)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 13.379"
                    width={width || '1.6rem'}
                    height={height}
                >
                    <defs>
                        <clipPath id="a">
                            <path
                                data-name="Path 10"
                                d="M139.21 178h16v13.379h-16Z"
                                fill={color || alpha(theme.palette.text.primary, 0.5)}
                            />
                        </clipPath>
                    </defs>
                    <g
                        data-name="pobrane (5)"
                        transform="translate(-139.21 -178)"
                        clipPath="url(#a)"
                    >
                        <path
                            data-name="Path 9"
                            d="M144.543 184.69a3.333 3.333 0 1 1 3.333-3.333 3.3 3.3 0 0 1-3.333 3.333Zm0-5.333a2 2 0 1 0 2 2 1.964 1.964 0 0 0-2-2.001Zm4.733 12h-9.4a.63.63 0 0 1-.667-.667 5.349 5.349 0 0 1 5.333-5.333h.067a5.279 5.279 0 0 1 5.333 5.2v.133a.668.668 0 0 1-.667.667Zm-8.6-1.333h7.867a4 4 0 0 0-3.933-3.333 3.9 3.9 0 0 0-3.933 3.332Zm12.533-8.667a3.3 3.3 0 0 0-3.333-3.333 2.8 2.8 0 0 0-1.6.467.686.686 0 1 0 .667 1.2 2.001 2.001 0 1 1 2 3.467 2.124 2.124 0 0 1-2 0 .686.686 0 0 0-.667 1.2 3.349 3.349 0 0 0 1.6.4 3.4 3.4 0 0 0 3.334-3.4Zm2 9.333a5.264 5.264 0 0 0-5.133-5.333h-.133a1.847 1.847 0 0 0-.6.067.67.67 0 1 0 .133 1.333h.467a3.953 3.953 0 0 1 3.933 3.267h-2.667a.667.667 0 0 0 0 1.333h3.333a.63.63 0 0 0 .666-.667Z"
                            fill={color || alpha(theme.palette.text.primary, 0.5)}
                        />
                    </g>
                </svg>
            )
        case 'contractor':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width || '1.6rem'}
                    height={height}
                    viewBox="0 0 18 13.91"
                >
                    <path
                        d="M9.578.002a.612.612 0 0 0-.249.07L5.564 2.047H.616a.614.614 0 0 0-.614.614v6.341a.614.614 0 0 0 .614.614h1.24a1.835 1.835 0 0 0 .607 1.17 2.4 2.4 0 0 0 .281.211c.124.087.285.2.473.326.377.255.86.579 1.342.9l1.342.895c.377.252.692.473.684.467a1.646 1.646 0 0 0 .984.326 1.622 1.622 0 0 0 .837-.237 1.58 1.58 0 0 0 .8.237 10.3 10.3 0 0 0 3.126-.805 15.811 15.811 0 0 0 3.1-1.47l.006-.006.013-.006v-.006a1.523 1.523 0 0 0 .709-1.183 1.545 1.545 0 0 0-.179-.71c-.023-.042-.052-.07-.077-.109h1.483a.614.614 0 0 0 .614-.614V2.661a.614.614 0 1 0-1.227 0v5.727h-.818a3.61 3.61 0 0 0-.729.038 1.691 1.691 0 0 0-.23.115L9.864 2.858a.614.614 0 0 0-.7-.147L6.3 3.951a.412.412 0 0 1-.166.038.4.4 0 0 1-.409-.409.409.409 0 0 1 .16-.32l.051-.032.109-.058 3.586-1.873 4.078 1.911a.615.615 0 0 0 .262.064h1.368a.614.614 0 1 0 0-1.227h-1.227L9.874.057a.613.613 0 0 0-.3-.058ZM1.23 3.275h3.343a1.557 1.557 0 0 0-.07.307 1.632 1.632 0 0 0 2.295 1.5l2.454-1.067 5.42 6 .013.013a2.315 2.315 0 0 1 .224.288.167.167 0 0 1 .026.121c0 .1.062.025-.166.173l-.013.006a15.831 15.831 0 0 1-2.851 1.342 12.247 12.247 0 0 1-2.7.729h-.057a1.61 1.61 0 0 0 .057-.409 1.633 1.633 0 0 0-.486-1.163c-.084-.083-.135-.122-.249-.217s-.265-.214-.441-.358a229.194 229.194 0 0 0-2.557-2.065c-.37-.294-.6-.478-.761-.588a1.847 1.847 0 0 0-2.563.5h-.92Zm2.455 5.523a.592.592 0 0 1 .339.1s.324.24.69.53.828.661 1.285 1.029.91.737 1.259 1.023c.174.143.319.267.428.358a1.39 1.39 0 0 0 .173.141.406.406 0 0 1 .121.294.4.4 0 0 1-.409.409.394.394 0 0 1-.243-.083c-.132-.1-.363-.245-.741-.5s-.861-.58-1.342-.9-.965-.643-1.336-.895c-.186-.126-.344-.232-.46-.313l-.173-.121-.006-.006-.006-.006a.615.615 0 0 1 .422-1.061Z"
                        fill={color || alpha(theme.palette.text.primary, 0.5)}
                    />
                </svg>
            )
        case 'logout':
            return (
                <svg
                    data-name="pobrane (7)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 17.635"
                    width={width || '1.6rem'}
                    height={height}
                >
                    <path
                        data-name="Path 11"
                        d="M12.01 2.722a.973.973 0 1 0-.973 1.685 6.052 6.052 0 1 1-6.068-.006.973.973 0 1 0-.973-1.685 8 8 0 1 0 8.014.005Z"
                        fill={color || alpha(theme.palette.text.primary, 0.5)}
                    />
                    <path
                        data-name="Path 12"
                        d="M8.001 5.227a.974.974 0 0 0 .973-.973V.971a.973.973 0 0 0-1.946 0v3.281a.974.974 0 0 0 .973.975Z"
                        fill={color || alpha(theme.palette.text.primary, 0.5)}
                    />
                </svg>
            )
        default:
            return <div>Icon</div>
    }
}

export default Icon
