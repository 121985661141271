import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const Progress = () => {
    return (
        <Box display="flex" justifyContent="center">
            <CircularProgress thickness={2.6} />
        </Box>
    )
}

export default Progress
