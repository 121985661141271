import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import logo from '../../assets/images/scnx_small.png'
import { Logo, StyledContent, StyledForm, StyledLogoWrapper, StyledSignIn } from './styles'
import FormSignIn from '../../modules/FormSignIn'
import { FirebaseContext } from '../../firebase'
import { PATHS } from '../../routes/routes'
import { useNavigate } from 'react-router-dom'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { COLLECTIONS } from '../../utils/constans'
import { userConverter } from '../../firebase/converters'
import { useSnackbar } from '../../hooks/useSnackbar'
import { parseMessage } from '../../utils/helpers'
import {
    AuthError,
    browserLocalPersistence,
    setPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth'
import useAuth from '../../hooks/useAuth'

class CustomAuthError extends Error {
    code: string
    constructor(code: string) {
        super()
        this.code = code
    }
}

const SignIn = () => {
    const { auth: authFirebase, db } = useContext(FirebaseContext)
    let navigate = useNavigate()
    const [alertError] = useSnackbar()
    const { auth } = useAuth()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        auth.user && !loading && navigate(PATHS.DASHBOARD)
    }, [auth.user, loading, navigate])

    const onSubmit = async (values: { email: string; password: string }) => {
        setLoading(true)
        //allow only admin users - which exists only in auth service - if user exists in firestore then throw err
        try {
            const docRef = query(
                collection(db, COLLECTIONS.USERS),
                where('email', '==', values.email)
            ).withConverter(userConverter)
            const querySnapshot = await getDocs(docRef)
            const user = querySnapshot?.docs?.[0]?.data()
            if (user) throw new CustomAuthError('auth/wrong-password')

            await setPersistence(authFirebase, browserLocalPersistence)
            await signInWithEmailAndPassword(authFirebase, values.email, values.password)
        } catch (error) {
            alertError(parseMessage(error as AuthError))
        } finally {
            setLoading(false)
        }
    }

    return (
        <StyledSignIn>
            <StyledForm variant="outlined">
                <StyledLogoWrapper>
                    <Logo src={logo} alt="scandinavian-logo" />
                </StyledLogoWrapper>
                <StyledContent>
                    <Typography variant="h3" mb={4} lineHeight={1.6}>
                        Zaloguj się do panelu <br /> administracyjnego
                    </Typography>

                    <FormSignIn onSubmit={onSubmit} loading={loading} />
                </StyledContent>
            </StyledForm>
        </StyledSignIn>
    )
}

export default SignIn
