import React, { FormEvent, FormEventHandler } from 'react'

type Props = {
    onSubmit: FormEventHandler<HTMLFormElement>
    children: JSX.Element
}

const Form = ({ onSubmit, children }: Props) => {
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit(e)
    }

    return <form onSubmit={(e) => handleSubmit(e)}>{children}</form>
}

export default Form
