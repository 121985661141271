import { Box, InputBase } from '@mui/material'
import { styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'
import { grey } from '@mui/material/colors'

export const StyledHeading = styled(Box)`
    height: ${({ theme }) => `${theme.utils.headerHeight}px`};
    display: flex;
    align-items: center;
    .MuiInputBase-root {
        font-size: 1.4rem;
    }
    .MuiInputLabel-root {
        padding: 0;
    }
`
export const StyledSearchIcon = styled(SearchIcon)`
    margin: ${({ theme }) => `7px 0 0 0`};
`

export const StyledSearchInput = styled(InputBase)`
    margin: ${({ theme }) => `0 ${theme.spacing(1)} 0 ${theme.spacing(2)}`};
    padding: ${({ theme }) => `0 0 0 ${theme.spacing(2)}`};
    border: 2px solid transparent;
    flex-shrink: 0;
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
    &.Mui-focused {
        border: ${({ theme }) => `2px solid ${grey[300]}`};
    }
`
