import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiInputLabel: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            position: 'static',
            transform: 'none',
            fontSize: '1.1rem',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: theme.palette.text.secondary,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        }),
    },
    variants: [],
}

export default MuiInputLabel
