import React from 'react'
import { MenuList } from '@mui/material'
import { PATHS } from '../../routes/routes'
import { StyledLogo, StyledMenuItem, StyledNavigation, StyledSidebar } from './styles'
import NavLink from '../../components/NavLink'
import logo from '../../assets/images/scnx_small.png'
import Icon from '../../components/Icon'
import theme from '../../theme'

type Props = {}
const activeColor = theme.palette.primary.main

const Sidebar = (props: Props) => {
    return (
        <StyledSidebar>
            <StyledLogo src={logo} alt="" />
            <StyledNavigation>
                <MenuList>
                    <StyledMenuItem>
                        <NavLink
                            to={PATHS.OBJECTS}
                            icon={(active) => (
                                <Icon icon="object" {...(active && { color: activeColor })} />
                            )}
                        >
                            Obiekty
                        </NavLink>
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <NavLink
                            to={PATHS.USERS}
                            icon={(active) => (
                                <Icon icon="user" {...(active && { color: activeColor })} />
                            )}
                        >
                            Uzytkownicy
                        </NavLink>
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <NavLink
                            to={PATHS.CONTRACTORS}
                            icon={(active) => (
                                <Icon icon="contractor" {...(active && { color: activeColor })} />
                            )}
                        >
                            Kontrahenci
                        </NavLink>
                    </StyledMenuItem>
                </MenuList>
            </StyledNavigation>
            <NavLink
                to={PATHS.SIGNOUT}
                icon={(active) => <Icon icon="logout" {...(active && { color: activeColor })} />}
            >
                Wyloguj się
            </NavLink>
        </StyledSidebar>
    )
}

export default Sidebar
