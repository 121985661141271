import React, { MouseEventHandler, useEffect } from 'react'
import Input from '../../components/Input'
import Form from '../../components/Form'
import { FORM_OBJECT } from '../../utils/constans'
import FormFooter from '../../components/FormFooter'
import { Backdrop } from '@mui/material'
import Progress from '../../components/Progress'
import { Option } from '../../types'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { InputFields } from 'forms'
import { ObjectForm } from 'objects'

type Props = {
    onCancel: MouseEventHandler<HTMLAnchorElement>
    options: { [name: string]: Array<Option> | undefined }
    disabledOptions?: { [name: string]: Array<string> | undefined }
    initialValues?: ObjectForm
    onSubmitText: string
    loading: boolean
    onSubmit: SubmitHandler<ObjectForm>
}

const FormObject = ({
    onCancel,
    options,
    disabledOptions,
    initialValues,
    onSubmitText,
    loading,
    onSubmit,
}: Props) => {
    const methods = useForm<ObjectForm>()
    const { handleSubmit, reset } = methods

    useEffect(() => {
        reset(initialValues)
    }, [initialValues, reset])

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <>
                    {FORM_OBJECT.map(
                        ({ name, type, label, placeholder, isRequired }: InputFields, i) => (
                            <Input
                                key={i}
                                name={name}
                                type={type}
                                label={label}
                                placeholder={placeholder}
                                isRequired={isRequired}
                                options={options[name]}
                                disabledOptions={disabledOptions?.[name]}
                            />
                        )
                    )}
                    <FormFooter
                        onSubmitText={onSubmitText || 'OK'}
                        onCancel={onCancel}
                        onSubmitDisable={loading}
                    />
                    <Backdrop open={loading} invisible={true}>
                        <Progress />
                    </Backdrop>
                </>
            </Form>
        </FormProvider>
    )
}

export default FormObject
