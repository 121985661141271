import React, { MouseEventHandler, useEffect } from 'react'
import Input from '../../components/Input'
import Form from '../../components/Form'
import { FORM_USER, LANGUAGES } from '../../utils/constans'
import FormFooter from '../../components/FormFooter'
import { Option } from '../../types'
import { Backdrop, Grid } from '@mui/material'
import Progress from '../../components/Progress'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { UserForm } from 'users'

interface Props {
    onSubmit: SubmitHandler<UserForm>
    onCancel: MouseEventHandler<HTMLAnchorElement>
    options: { [name: string]: Array<Option> | undefined }
    disabledOptions?: { [name: string]: Array<string> | undefined }
    initialValues?: UserForm
    onSubmitText: string
    loading: boolean
}

const FormUser = ({
    onCancel,
    options,
    disabledOptions,
    initialValues,
    onSubmitText,
    loading,
    onSubmit,
}: Props) => {
    const methods = useForm<UserForm>({
        defaultValues: {
            lang: {
                name: LANGUAGES.PL.name,
                value: LANGUAGES.PL.code,
            },
        },
    })
    const { handleSubmit, reset } = methods

    useEffect(() => {
        reset(initialValues)
    }, [initialValues, reset])

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <>
                    <Grid container>
                        {FORM_USER.map(
                            ({ name, type, label, pattern, placeholder, isRequired }, i) => (
                                <Grid item key={i} xs={type === 'multiselect' ? 12 : 6} px={2}>
                                    <Input
                                        name={name}
                                        type={type}
                                        label={label}
                                        pattern={pattern}
                                        placeholder={placeholder}
                                        options={options[name]}
                                        disabledOptions={disabledOptions?.[name]}
                                        isRequired={isRequired}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                    <FormFooter
                        onSubmitText={onSubmitText || 'OK'}
                        onCancel={onCancel}
                        onSubmitDisable={loading}
                    />
                    <Backdrop open={loading} invisible={true}>
                        <Progress />
                    </Backdrop>
                </>
            </Form>
        </FormProvider>
    )
}

export default FormUser
