import { grey } from '@mui/material/colors'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiTypography: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        body1: {
            fontSize: '1.4rem',
        },
        subtitle1: {
            fontWeight: 700,
            fontSize: '.9rem',
        },
        h1: {
            fontSize: '2.8rem',
            fontWeight: 600,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '1.1rem',
            color: grey[400],
        },
        h5: {
            fontSize: '1.1rem',
            color: grey[400],
            textTransform: 'uppercase',
            fontWeight: 600,
        },
    },
    variants: [],
}

export default MuiTypography
