import React from 'react'
import { FormControl, Grid, InputLabel, Typography, IconButton } from '@mui/material'
import { StyledHeading, StyledSearchIcon, StyledSearchInput } from './styles'
import { Box } from '@mui/system'
import useSearch from '../../hooks/useSearch'
import CloseIcon from '@mui/icons-material/Close'

const Header = () => {
    const { inputSearchValue, handleChangeInputSearchValue, resetInputSearchValue } = useSearch()

    return (
        <StyledHeading px={5}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <Box display={'flex'} alignItems={'flex-end'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <InputLabel htmlFor="outlined-password-input">
                                <StyledSearchIcon />
                            </InputLabel>
                            <FormControl>
                                <StyledSearchInput
                                    id="outlined-password-input"
                                    type="text"
                                    placeholder="Szukaj"
                                    autoComplete="current-password"
                                    onChange={handleChangeInputSearchValue}
                                    value={inputSearchValue}
                                    endAdornment={
                                        <IconButton
                                            aria-label="delete"
                                            onClick={resetInputSearchValue}
                                            sx={{ opacity: inputSearchValue ? 1 : 0 }}
                                        >
                                            <CloseIcon fontSize={'medium'} />
                                        </IconButton>
                                    }
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Typography align="right" variant="h3" mb={1}>
                        Scandinavian Express
                    </Typography>
                    <Typography align="right" variant="h4">
                        Administrator
                    </Typography>
                </Grid>
            </Grid>
        </StyledHeading>
    )
}

export default Header
