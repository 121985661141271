import { Box, Paper } from '@mui/material'
import { styled } from '@mui/system'
import bg from '../../assets/images/bg.png'

export const StyledContent = styled(Box)`
    background-color: ${({ theme }) => theme.palette.grey[100]};
    padding: ${({ theme }) => theme.spacing(4)};
`
export const StyledSignIn = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
`

export const StyledLogoLink = styled('a')`
    transition: 300ms;
    display: block;
    margin-bottom: 20px;
    &:hover {
        transform: scale(1.05);
    }
`

export const StyledForm = styled(Paper)`
    width: 100%;
    max-width: 460px;
    box-shadow: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
        max-width: 175px;
    }
`

export const Logo = styled('img')`
    height: 100px;
`
export const StyledLogoWrapper = styled(Box)`
    box-shadow: 0 4px 8px -2px ${({ theme }) => theme.palette.grey[200]};
    padding: ${({ theme }) => theme.spacing(4)};
    z-index: 2;
`
