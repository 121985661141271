import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiTextField: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        standard: ({ theme }: { theme: Theme }) => ({
            backgroundColor: 'transparent',
        }),
    },
    variants: [
        {
            props: { variant: 'clean' },
            style: {
                textTransform: 'none',
                border: `0`,
            },
        },
    ],
}

export default MuiTextField
