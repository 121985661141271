import { collection, doc, getDocs, query, Timestamp, where } from 'firebase/firestore'
import React, { LegacyRef, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { User } from 'users'
import { FirebaseContext } from '../../firebase'
import { format, fromUnixTime, sub } from 'date-fns'
import { CategoryScale, Chart, LinearScale, LineElement, PointElement } from 'chart.js'
import { Line } from 'react-chartjs-2'
import theme from '../../theme'
import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Tooltip,
    Typography,
} from '@mui/material'
import useClipboard from '../../hooks/useClipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { StyledTable, StyledTableTd, StyledTableTh } from './styles'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useTooltip from '../../hooks/useTooltip'

type Props = {
    data?: User[]
}

type UserActivity = {
    activityNumber?: string
    lastUpdate: Timestamp
}

interface UserWithStats extends User {
    activityData: {
        activity: UserActivity[]
        lastActivity: string
        activitySum: number
    }
}

const UserStatistics = ({ data }: Props) => {
    useMemo(() => Chart.register(CategoryScale, LinearScale, PointElement, LineElement), [])

    const { db } = useContext(FirebaseContext)
    const tableRef: LegacyRef<HTMLTableElement> = useRef(null)
    const { setClipboard } = useClipboard()
    const { open, handleCloseTooltip, handleShowTooltip } = useTooltip()
    const [userData, setUserData] = useState<UserWithStats[] | [] | null>(null)
    const [chart, setChart] = useState([])

    const handleCopyHTML = () =>
        tableRef?.current &&
        navigator.clipboard.write(setClipboard(tableRef?.current?.outerHTML)).then(() => {
            handleShowTooltip()
        })

    useEffect(() => {
        Chart.defaults.font.size = 11
        Chart.defaults.font.family = 'Barlow'

        const getStats = () => {
            return data?.map(async (user) => {
                const docRef = doc(db, `users/${user?.id}`)
                const activityRef = collection(docRef, 'activity')
                const q = query(
                    activityRef,
                    where('lastUpdate', '>', sub(new Date(), { months: 3 }))
                )

                return await getDocs(q)
            })
        }
        const stats = getStats()

        Promise.all(stats || [])
            .then((item) => {
                let userList: any = []
                item.forEach((querySnapshot, i) => {
                    let activityData = {
                        lastActivity: '',
                        activitySum: null,
                        activity: [],
                    } as any

                    querySnapshot.docs.forEach((doc) => {
                        activityData = {
                            ...activityData,
                            activity: [...activityData.activity, doc.data()],
                        }
                    })
                    if (!activityData.activity.length) return

                    //last activity
                    const activityTimestamps = activityData.activity.map(
                        ({ lastUpdate }: any) => lastUpdate?.seconds
                    )

                    const lastActivity = Math.max(...activityTimestamps)
                    const lastActivityDate = format(fromUnixTime(lastActivity), 'dd/MM/yyyy')
                    activityData = { ...activityData, lastActivity: lastActivityDate }

                    //activity number sum
                    let activitySum = activityData.activity.reduce(
                        (acc: any, { activityNumber }: { activityNumber: number | string }) => {
                            if (!acc?.activityNumber) return Number(acc) + Number(activityNumber)
                            return Number(acc.activityNumber) + Number(activityNumber)
                        }
                    )

                    if (activitySum?.activityNumber) activitySum = activitySum?.activityNumber

                    activityData = { ...activityData, activitySum }
                    userList = [{ ...data?.[i], activityData }, ...userList]
                    setUserData(userList)

                    //chart
                    const chartData = userList
                        ?.map((user: UserWithStats) => {
                            const data: any = (user?.activityData as any)?.activity.map(
                                ({ activityNumber }: { activityNumber: string }) =>
                                    Number(activityNumber)
                            )

                            return (user?.activityData as any)?.activity.map(
                                ({ lastUpdate }: { lastUpdate: number }, j: number) => ({
                                    label: lastUpdate,
                                    data: data[j],
                                })
                            )
                        })
                        .reduce((prev: any, item: any, i: number, arr: any) => {
                            return [...prev, ...item]
                        })
                        .sort((a: any, b: any) => {
                            return a.label - b.label
                        })

                    const result = Object.values(
                        chartData.reduce((acc: any, item: any) => {
                            acc[item.label] = acc[item.label]
                                ? { ...item, data: item.data + acc[item.label].data }
                                : item
                            return acc
                        }, {})
                    )
                    setChart(result.filter((r: any) => !!r.data) as any)
                })
            })
            .catch((error) => console.log(error))
    }, [data, db])

    const chartConfig = useMemo(
        () => ({
            labels: chart.map((item) => format(fromUnixTime((item as any).label), 'dd/MM')),
            datasets: [
                {
                    data: chart.map((item) => (item as any).data),
                    borderColor: theme.palette.secondary.light,
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                },
            ],
        }),
        [chart]
    )

    const options = useMemo(
        () => ({
            responsive: true,
            type: 'line',
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },

                y: {
                    grid: {
                        display: false,
                    },
                },
            },
            pointStyles: {
                backgroundColor: 'transparent',
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
            plugins: {
                legend: {
                    position: 'top' as const,
                    labels: {
                        usePointStyle: false,
                    },
                },
            },
        }),
        []
    )

    if (!data?.length)
        return (
            <Box display="flex" alignItems={'center'}>
                <HighlightOffIcon sx={{ margin: '0 5px 0 0' }} />
                <Typography>Obiekt nie posiada przypisanych użytkowników</Typography>
            </Box>
        )

    if (userData === null)
        return (
            <Box
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ height: '100%', minHeight: '450px' }}
            >
                <CircularProgress />
            </Box>
        )

    if (!userData?.length)
        return (
            <Box display="flex" alignItems={'center'}>
                <HighlightOffIcon sx={{ margin: '0 5px 0 0' }} />
                <Typography>Brak statystyk dla wybranego obiektu</Typography>
            </Box>
        )

    return (
        <div>
            {chart?.length && <Line options={options} data={chartConfig} />}
            <>
                <div>
                    <StyledTable ref={tableRef}>
                        <thead>
                            <tr>
                                <StyledTableTh>Użytkownik</StyledTableTh>
                                <StyledTableTh>Ilość użyć aplikacji</StyledTableTh>
                                <StyledTableTh>Ostatnie logowanie</StyledTableTh>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.map((user: UserWithStats) => (
                                <tr key={user.id}>
                                    <StyledTableTd>
                                        {user.name} {user.lastName}
                                    </StyledTableTd>
                                    <StyledTableTd>{user.activityData.activitySum}</StyledTableTd>
                                    <StyledTableTd>{user.activityData.lastActivity}</StyledTableTd>
                                </tr>
                            ))}
                        </tbody>
                    </StyledTable>
                </div>
                <ClickAwayListener onClickAway={handleCloseTooltip}>
                    <div>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleCloseTooltip}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="left"
                            title={<Typography>Skopiowano!</Typography>}
                        >
                            <Button
                                variant="contained"
                                onClick={handleCopyHTML}
                                sx={{ display: 'flex', marginLeft: 'auto' }}
                            >
                                <ContentCopyIcon sx={{ margin: '0 15px 0 0' }} /> Kopiuj dane do
                                schowka
                            </Button>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
            </>
        </div>
    )
}

export default UserStatistics
